// user role
export const roleListApi = '/role/list'
export const roleStoreApi = '/role/store'
export const roleUpdateApi = '/role/update'
export const roleToggleStatusApi = '/role/toggle-status'

// user
export const secretQuestionApi = 'secret-question-dropdown'
export const officeUserList = 'user/current-office-user'
export const userList = '/user/list'
export const userData = '/user/user-data'
export const userUpdate = '/user/update-user'
export const userStore = '/user/store'
export const userDelete = '/user/destroy'
export const userToggleStatus = '/user/toggle-status'

export const LogReportListApi = '/log-report/list'
export const orgWiseComponentApi = '/component/org-wise-component'
export const officeDesignationsApi = '/assign-designation/office-designations'
