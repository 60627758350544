<template>
  <b-container fluid>
    <iq-card>
      <template v-slot:headerTitle>
        <h4 class="card-title">{{ $t('globalTrans.search') }}</h4>
      </template>
      <template v-slot:body>
        <ValidationObserver ref="form" v-slot="{ handleSubmit, reset }">
          <b-form  @submit.prevent="handleSubmit(register)" @reset.prevent="reset">
            <b-row>
                <b-col xs="12" sm="12" md="6">
                  <ValidationProvider name="Org Id" vid="org_id" rules="required|min_value:1">
                    <b-form-group
                      class="row"
                      label-cols-sm="3"
                      label-for="org_id"
                      slot-scope="{ valid, errors }"
                    >
                      <template v-slot:label>
                        {{$t('user_role.organization')}} <span class="text-danger">*</span>
                      </template>
                      <b-form-select
                          plain
                          v-model="search.org_id"
                          :options="orgList"
                          :state="errors[0] ? false : (valid ? true : null)"
                        >
                        <template v-slot:first>
                          <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                        </template>
                      </b-form-select>
                      <div class="invalid-feedback">
                        {{ errors[0] }}
                      </div>
                    </b-form-group>
                  </ValidationProvider>
                </b-col>
                <b-col xs="12" sm="12" md="6">
                  <ValidationProvider name="component Id" vid="component_id" rules="required|min_value:1">
                    <b-form-group
                        class="row"
                        label-cols-sm="3"
                        label-for="role_id"
                        slot-scope="{ valid, errors }"
                    >
                    <template v-slot:label>
                        {{$t('org_pro.component')}} <span class="text-danger">*</span>
                    </template>
                    <b-form-select
                        plain
                        v-model="search.component_id"
                        :options="componentList"
                        id="component_id"
                        :state="errors[0] ? false : (valid ? true : null)"
                        >
                        <template v-slot:first>
                        <b-form-select-option :value="0" disabled>{{ $t('globalTrans.select') }} <span class="text-danger">*</span></b-form-select-option>
                        </template>
                    </b-form-select>
                      <div class="invalid-feedback">
                        {{ errors[0] }}
                      </div>
                    </b-form-group>
                  </ValidationProvider>
                </b-col>
            </b-row>
            <b-row>
              <b-col xs="12" sm="12" md="6">
                  <b-form-group
                      class="row"
                      label-cols-sm="3"
                      label-for="role_id"
                  >
                      <template v-slot:label>
                          {{$t('log_report.user')}}
                      </template>
                      <b-form-select
                          plain
                          v-model="search.user_id"
                          :options="users"
                          id="user_id"
                          >
                          <template v-slot:first>
                          <b-form-select-option :value="0">{{ $t('globalTrans.select') }}</b-form-select-option>
                          </template>
                          </b-form-select>
                      </b-form-group>
              </b-col>
              <b-col xs="12" sm="12" md="6">
                <b-form-group
                  class="row"
                  label-cols-sm="3"
                  label-for="role_id"
                >
                <template v-slot:label>
                  {{$t('log_report.execution_type')}}
                </template>
                <b-form-select
                  plain
                  v-model="search.execution_type"
                  :options="executionType"
                  id="executionType"
                  >
                  <template v-slot:first>
                    <b-form-select-option :value=-1>{{ $t('globalTrans.select') }}</b-form-select-option>
                  </template>
                  </b-form-select>
                </b-form-group>
              </b-col>
              <b-col xs="12" sm="12" md="6">
                <ValidationProvider name="From Date" vid="from_date" rules="required">
                  <b-form-group
                      class="row"
                      label-cols-sm="3"
                      label-for="from_date"
                      slot-scope="{ valid, errors }"
                  >
                      <template v-slot:label>
                          {{$t('globalTrans.from_date')}} <span class="text-danger">*</span>
                      </template>
                      <b-form-input class="form-control"
                          v-model="search.from_date"
                          placeholder="Select Date"
                          id="from_date"
                          :state="errors[0] ? false : (valid ? true : null)"
                      ></b-form-input>
                     <div class="invalid-feedback">
                        {{ errors[0] }}
                      </div>
                  </b-form-group>
                </ValidationProvider>
              </b-col>
              <b-col xs="12" sm="12" md="6">
                <ValidationProvider name="To Date" vid="to_date" rules="required">
                  <b-form-group
                      class="row"
                      label-cols-sm="3"
                      label-for="to_date"
                       slot-scope="{ valid, errors }"
                  >
                      <template v-slot:label>
                          {{$t('globalTrans.to_date')}} <span class="text-danger">*</span>
                      </template>
                      <b-form-input class="form-control"
                          v-model="search.to_date"
                          placeholder="Select Date"
                          id="to_date"
                          :state="errors[0] ? false : (valid ? true : null)"
                      ></b-form-input>
                     <div class="invalid-feedback">
                        {{ errors[0] }}
                      </div>
                  </b-form-group>
                </ValidationProvider>
              </b-col>
            </b-row>
            <b-row>
                <b-col xs="12" sm="12" md="6">
                  <b-button type="submit" variant="primary">{{ $t('globalTrans.search')}}</b-button>
                </b-col>
            </b-row>
           </b-form>
        </ValidationObserver>
      </template>
    </iq-card>
    <b-row v-show="showData">
      <b-col md="12">
        <iq-card>
          <template v-slot:headerTitle>
            <h4 class="card-title">{{ $t('log_report.list') }}</h4>
          </template>
          <template v-slot:headerAction>
            <export-excel
              class="btn btn-success mr-2"
              :data="dataCustomize"
              :fields= "json_fields"
              :title="$t('log_report.list')"
              :name="$t('log_report.list') + '.xls'">
              {{ $t('globalTrans.export_excel') }}
            </export-excel>
            <b-button variant="primary" @click="pdfExport" class="mr-2">
              {{  $t('globalTrans.export_pdf') }}
            </b-button>
          </template>
          <template v-slot:body>
            <b-overlay :show="loadingState">
              <b-row>
                <b-col md="12" class="table-responsive">
                  <b-table class="table-striped" bordered hover :emptyText="$t('globalTrans.noDataFound')" show-empty :items="listData" :fields="columns" aria-hidden="loading | listReload ? 'true' : null">
                    <template v-slot:cell(index)="data">
                      {{ $n(data.index + 1) }}
                    </template>
                    <template v-slot:cell(user)="data">
                      {{ data.item.user_name }}
                    </template>
                    <template v-slot:cell(link)="data">
                      {{ data.item.menu_name }}
                    </template>
                    <template v-slot:cell(component)="data">
                      {{ ($i18n.locale === 'bn') ? data.item.com_name_bn : data.item.com_name }}
                    </template>
                    <template v-slot:cell(dateandtime)="data">
                      {{ data.item.created_at | dateFormatTime }}
                    </template>
                    <template v-slot:cell(action)="data">
                      <span class="badge badge-success" v-if="data.item.execution_type == 0">{{$t('log_report.insert')}}</span>
                      <span class="badge badge-dark" v-else-if="data.item.execution_type == 1">{{$t('log_report.update')}}</span>
                      <span class="badge badge-danger" v-else-if="data.item.execution_type == 2">{{$t('log_report.delete')}}</span>
                    </template>
                    <template v-slot:cell(org_id)="data">
                      {{ ($i18n.locale === 'bn') ? data.item.org_name_bn : data.item.org_name }}
                    </template>
                    <template v-slot:cell(status)="data">
                      {{ data.item.status ? $t('globalTrans.inactive') : $t('globalTrans.active') }}
                    </template>
                  </b-table>
                </b-col>
              </b-row>
            </b-overlay>
          </template>
        </iq-card>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
import { core } from '@/config/pluginInit'
import { dateFormatTime } from '@/Utils/fliter'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import { mapGetters } from 'vuex'
import RestApi, { authServiceBaseUrl, commonServiceBaseUrl, irriSchemeServiceBaseUrl, warehouseServiceBaseUrl } from '@/config/api_config'
import { LogReportListApi, orgWiseComponentApi } from '../../api/routes'
import ModalBaseMasterList from '@/mixins/modal-base-master-list'
import ExportPdf from '@/Utils/export-pdf'
import excel from 'vue-excel-export'
import Vue from 'vue'
import flatpickr from 'flatpickr'

Vue.use(excel)
const excelColumn = {
  Serial: 'serial',
  Component: 'component',
  User: 'user_name',
  Link: 'menu_name',
  Action: 'execution_type',
  'Date And Time': 'created_at'
}
const excelColumnBn = {
  সিরিয়াল: 'serial',
  উপাদান: 'component',
  কে: 'user_name',
  কোথায়: 'menu_name',
  কি: 'execution_type',
  'তারিখ এবং সময়': 'created_at'
}

export default {
  mixins: [ModalBaseMasterList],
  data () {
    return {
      showData: false,
      search: {
        org_id: 0,
        execution_type: -1,
        user_id: 0,
        component_id: 0,
        from_date: null,
        to_date: null
      },
      testId: 0,
      rows: [],
      users: [],
      baseUrl: {
        '-1': authServiceBaseUrl,
        1: commonServiceBaseUrl,
        9: irriSchemeServiceBaseUrl,
        6: warehouseServiceBaseUrl
      },
      user_detail: '',
      compData: []
    }
  },
  computed: {
    orgList: function () {
      return this.$store.state.orgList.filter(item => item.status === 0)
    },
    json_fields: function () {
      return this.$i18n.locale === 'bn' ? excelColumnBn : excelColumn
    },
    dataCustomize () {
      const listData = this.listData
      var serial = 0
      const listContractor = listData.map(item => {
        serial += 1
        let executionType = ''
        if (item.execution_type === 0) {
          executionType = this.$t('log_report.insert')
        } else if (item.execution_type === 1) {
          executionType = this.$t('log_report.update')
        } else if (item.execution_type === 3) {
          executionType = this.$t('log_report.delete')
        }
        return Object.assign({}, item, {
          component: this.$i18n.locale === 'en' ? item.com_name : item.com_name_bn,
          serial: this.$n(serial),
          execution_type: executionType,
          created_at: dateFormatTime(item.created_at)
        })
      })
      return listContractor
    },
    executionType: function () {
      const executionType = [
        {
          value: 0,
          text: this.$t('log_report.insert')
        },
        {
          value: 1,
          text: this.$t('log_report.update')
        },
        {
          value: 2,
          text: this.$t('log_report.delete')
        }
      ]
      return executionType
    },
    componentList: function () {
        const listObject = this.compData
        const tmpList = listObject.map((obj, index) => {
          if (this.$i18n.locale === 'bn') {
            return { value: obj.value, text: obj.text_bn }
          } else {
            return { value: obj.value, text: obj.text_en }
          }
        })
        return tmpList
    },
    columns () {
      const labels = [
        { label: this.$t('globalTrans.sl_no'), class: 'text-left' },
        { label: this.$t('org_pro.component'), class: 'text-left' },
        { label: this.$t('log_report.user'), class: 'text-left' },
        { label: this.$t('log_report.link'), class: 'text-left' },
        { label: this.$t('log_report.action'), class: 'text-left' },
        { label: this.$t('log_report.dateandtime'), class: 'text-left' }
      ]
      let keys = []
      if (this.$i18n.locale === 'bn') {
          keys = [
            { key: 'index' },
            { key: 'component' },
            { key: 'user' },
            { key: 'link' },
            { key: 'action' },
            { key: 'dateandtime' }
          ]
      } else {
          keys = [
            { key: 'index' },
            { key: 'component' },
            { key: 'user' },
            { key: 'link' },
            { key: 'action' },
            { key: 'dateandtime' }
          ]
      }
      return labels.map((item, index) => {
          return Object.assign(item, keys[index])
      })
    },
    ...mapGetters({
      commonObj: 'commonObj',
      authUser: 'Auth/authUser'
    })
  },
  watch: {
    'search.org_id': function (newVal, oldVal) {
      if (oldVal !== newVal) {
        this.getComponentList(newVal)
        if (this.authUser.user_id !== 1) {
          this.userData(newVal)
        }
      }
    },
    loadingState: function (newVal, oldVal) {
      if (newVal) {
        this.loadData()
      }
    }
  },
  created () {
  },
  mounted () {
    core.index()
    flatpickr('#from_date', {})
    flatpickr('#to_date', {})
    if (this.authUser.user_id === 1) {
      this.userData()
    }
    if (this.authUser.role_id === 0 && this.authUser.is_org_admin === 1) {
      this.search = Object.assign({}, this.search, {
        org_id: this.authUser.org_id
      })
    } else if (this.authUser.role_id === 0) {
      this.search = Object.assign({}, this.search, {
        org_id: this.authUser.office_detail.org_id
      })
    }
    this.loadData()
  },
  methods: {
    async getComponentList (orgId) {
      const orgSearch = {
        org_id: orgId
      }
      await RestApi.getData(commonServiceBaseUrl, orgWiseComponentApi, orgSearch).then(response => {
        if (response.success) {
          this.compData = response.data
        }
      }, error => {
        if (error) {
         //
        }
      })
    },
    async register () {
      if (this.search.from_date && this.search.to_date && this.search.component_id && this.search.org_id) {
        this.showData = true
        this.loadData()
      } else {
        this.$swal({
          title: this.$t('globalTrans.Pleasefillinalltherequiredfields'),
          showCancelButton: false,
          confirmButtonText: 'Ok',
          cancelButtonText: 'No',
          focusConfirm: false
        }).then((resultSwal) => {
        })
      }
    },
    details (item) {
      this.user_detail = item
    },
    async userData () {
      let userSearch = {
        org_id: 0,
        user_type_id: 0
      }
      if (this.authUser.user_id === 1) {
        userSearch = {
          org_id: null,
          user_type_id: null
        }
      } else {
        userSearch = {
          org_id: this.search.org_id,
          user_type_id: 0
        }
      }
      await RestApi.getData(authServiceBaseUrl, 'user/user-list', userSearch).then(response => {
        if (response.success) {
          this.users = response.data
        }
      }, error => {
        if (error) {
         //
        }
      })
    },
    async loadData () {
      const baseUrl = this.baseUrl[this.search.component_id]
      if (typeof baseUrl !== 'undefined') {
        this.$store.dispatch('mutateCommonProperties', { loading: true })
        const params = Object.assign({}, this.search, { page: this.pagination.currentPage, per_page: this.pagination.perPage })
        await RestApi.getData(baseUrl, LogReportListApi, params).then(response => {
          if (response.success) {
            this.$store.dispatch('setList', this.formatList(response.data))
          }
          this.$store.dispatch('mutateCommonProperties', { loading: false, listReload: false })
        }, error => {
          if (error) {
          //
          }
          this.$store.dispatch('mutateCommonProperties', { loading: false, listReload: false })
        })
      } else {
        this.$store.dispatch('setList', [])
      }
    },
    formatList (data) {
      try {
        const listData = data.map(item => {
          const userObject = this.users.find(user => user.value === item.user_id)
          const compObject = this.compData.find(comp => comp.value === this.search.component_id)
          const compData = {
            com_name: compObject.text_en,
            com_name_bn: compObject.text_bn
          }
          let userData = { user_name: item.username }
          if (userObject) {
            userData = {
              user_name: userObject.text
            }
          }
          return Object.assign(item, compData, userData)
        })
        return listData
      } catch (error) {
        if (error) {
         //
        }
        return []
      }
    },
    pdfExport () {
      const rowData = this.getPdfData()
      const labels = [
        { text: this.$t('globalTrans.sl_no'), fontSize: (this.$i18n.locale === 'bn' ? 14 : 12) },
        { text: this.$t('org_pro.component'), fontSize: (this.$i18n.locale === 'bn' ? 14 : 12) },
        { text: this.$t('log_report.user'), fontSize: (this.$i18n.locale === 'bn' ? 14 : 12) },
        { text: this.$t('log_report.link'), fontSize: (this.$i18n.locale === 'bn' ? 14 : 12) },
        { text: this.$t('log_report.action'), fontSize: (this.$i18n.locale === 'bn' ? 14 : 12) },
        { text: this.$t('log_report.dateandtime'), fontSize: (this.$i18n.locale === 'bn' ? 14 : 12) }
      ]
      rowData.unshift(labels)
      const reportTitle = this.$i18n.locale === 'en' ? 'Log Report' : 'লগ রিপোর্ট'
      const columnWids = ['10%', '20%', '20%', '20%', '15%', '15%']
      ExportPdf.exportPdf(irriSchemeServiceBaseUrl, '/report-heading/detail', 3, reportTitle, rowData, columnWids)
    },
    getPdfData () {
      const keys = [
        { key: 'serial_no' },
        { key: 'component' },
        { key: 'user_name' },
        { key: 'menu_name' },
        { key: 'execution_type' },
        { key: 'created_at' }
      ]
      var serial = 0
      const listData = this.dataCustomize.map(item => {
        serial += 1
        const rowData = keys.map((keyItem, index) => {
          if (keyItem.key === 'serial_no') {
            return { text: this.$n(serial) }
          }
          return { text: item[keyItem.key] }
        })
        return rowData
      })
      return listData
    }
  },
  components: {
    ValidationObserver,
    ValidationProvider
  }
}
</script>
